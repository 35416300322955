<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.name"
              label="发票名称"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入发票名称' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.customer"
              label="客户名称"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择客户' }]"
              @click="showPickerCustomer = true"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.order"
              label="订单"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择订单' }]"
              @click="showPickerOrder= true"
          />
          <van-popup v-model="showPickerOrder" position="bottom">
            <van-picker
                show-toolbar
                :columns="orderList"
                value-key="theme"
                @confirm="
                onConfirmOrder($event, 'orderId', 'order', 'theme', 'showPickerOrder')
              "
                @cancel="showPickerOrder = false"
            />
          </van-popup>

          <van-field
              v-model="formInline.orderPrice"
              label="订单金额"
              disabled
          />

          <van-field
              v-model="formInline.price"
              label="开票金额"
              type="number"
              @input="handleInputChange"
          />

          <van-field
              v-model="formInline.taxRate"
              label="税率(%)"
              type="number"
              @input="handleInputChange"
          />

          <van-field
              v-model="formInline.taxPrice"
              label="税额"
              disabled
          />

          <van-field
              readonly
              clickable
              v-model="formInline.leaderStr"
              label="负责人"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择负责人' }]"
              @click="showPop= true"
          />

          <van-field
              readonly
              clickable
              :value="formInline.invoiceDate"
              label="开票日期"
              placeholder="点击选择日期"
              :required="true"
              :rules="[{ required: true, message: '请选择开票日期' }]"
              @click="showPickerApply = true"
          />
          <van-popup v-model="showPickerApply" style="width: 70%">
            <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                title="请选择开票时间"
                @confirm="onConfirmApply"
                @cancel="showPickerApply = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              v-model="formInline.typeStr"
              label="开票类型"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择开票类型' }]"
              @click="showPickerType= true"
          />
          <van-popup v-model="showPickerType" position="bottom">
            <van-picker
                show-toolbar
                :columns="invoiceTypeList"
                value-key="dictLabel"
                @confirm="
                onConfirmType($event, 'type', 'typeStr', 'dictLabel', 'showPickerType')
              "
                @cancel="showPickerType = false"
            />
          </van-popup>

          <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

          <van-field
              readonly
              clickable
              v-model="formInline.headName"
              label="发票抬头"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择发票抬头' }]"
              @click="showPickerHead= true"
          />
          <van-popup v-model="showPickerHead" position="bottom">
            <van-picker
                show-toolbar
                :columns="invoiceHeadList"
                value-key="name"
                @confirm="
                onConfirmHead($event, 'headId', 'headName', 'name', 'showPickerHead')
              "
                @cancel="showPickerHead = false"
            />
          </van-popup>

          <van-field
              v-model="formInline.typeStr"
              label="抬头类型"
              disabled
          />

          <van-field
              v-model="formInline.number"
              label="纳税人识别号"
              disabled
          />

          <van-field
              v-model="formInline.bank"
              label="开户行"
              disabled
          />

          <van-field
              v-model="formInline.accountName"
              label="开户账号"
              disabled
          />

          <van-field
              v-model="formInline.accountAddress"
              label="开票地址"
              disabled
          />

          <van-field
              v-model="formInline.phone"
              label="电话"
              disabled
          />

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail' && isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>

    <CustomerPop :customerVisible="showPickerCustomer" @submitCustomerList="submitCustomerList"
                 @handleClose="handleCloseCustomer"/>

    <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>
  </div>
</template>
<script>
import {addCrmInvoice, fetchOrderListByCustomerId, selectItemByInvoice, updateCrmInvoice} from '@/api/crm/invoice';
import AppendixVue from '@/views/components/crm/Appendix.vue';
import CustomerPop from '@/components/crm/CustomerPop'
import SelectOneUser from '@/components/selectOneUser/index'
import {changeToCurrentDay} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";
import {getInvoiceHeadList} from "@/api/cw/invoiceHead/invoiceHead";

export default {
  components: {AppendixVue, SelectOneUser, CustomerPop},
  data() {
    return {
      formInline: {},
      title: '新建发票',
      id: this.$route.query.id,
      type: this.$route.query.type,
      uploader: [],
      uploadParams: {
        businessType: 'invoice',
        businessId: undefined,
        file: null,
      },
      showPickerCustomer: false,
      showPickerOrder: false,
      showPickerType: false,
      showPickerHead: false,
      showPop: false,
      showPickerApply: false,
      currentDate: new Date(),
      //订单数组
      orderList: [],
      //开票类型数组
      invoiceTypeList: [],
      //开票抬头数组
      invoiceHeadList: [],
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    // 获取开票类型  invoice_type
    this.getDicts('invoice_type').then(response => {
      this.invoiceTypeList = response.data
    })
    // 获取发票抬头
    getInvoiceHeadList().then(response => {
      this.invoiceHeadList = response.data
    })
    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {
          enterCategory: 0
        };
        this.title = '新建发票';
      } else {
        let itemDetail = await selectItemByInvoice({invoiceId: this.id});
        itemDetail = itemDetail.data
        itemDetail.headName = itemDetail.crmInvoiceHead.name
        itemDetail.typeStr = itemDetail.crmInvoiceHead.typeStr
        itemDetail.number = itemDetail.crmInvoiceHead.number
        itemDetail.bank = itemDetail.crmInvoiceHead.bank
        itemDetail.accountName = itemDetail.crmInvoiceHead.accountName
        itemDetail.accountAddress = itemDetail.crmInvoiceHead.accountAddress
        itemDetail.phone = itemDetail.crmInvoiceHead.phone
        this.formInline = itemDetail;
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑发票';
        } else {
          this.title = '发票详情';
        }
      }
    },

    //客户提交
    async submitCustomerList(id, name) {
      this.formInline.customerId = id;
      this.formInline.customer = name;

      //改变客户后，表单重置
      this.orderList = []
      this.formInline.orderId = null
      this.formInline.order = ''
      this.formInline.orderPrice = 0
      this.formInline.price = 0
      this.formInline.taxRate = 0
      this.formInline.taxPrice = 0
      const result = await fetchOrderListByCustomerId({customerId: this.formInline.customerId})
      this.orderList = result.data.records

      this.showPickerCustomer = false
    },

    //关闭选择客户的弹窗
    handleCloseCustomer() {
      this.showPickerCustomer = false;
    },

    //确认订单
    async onConfirmOrder(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['id'];
      this.formInline[keyName] = event[eventKey];

      let obj = this.orderList.find(item => item.id = this.formInline.orderId)
      this.formInline.orderPrice = obj.discountPrice
      this.formInline.price = obj.discountPrice

      this[visible] = false;
    },

    //日期提交
    onConfirmTime(value) {
      this.formInline.purchaseDate = changeToCurrentDay(value);
      this.showPickerApply = false;
    },

    //选择人提交
    popSubmit(activeId, names) {
      this.showPop = false
      this.formInline.leaderId = activeId
      this.formInline.leaderStr = names
    },

    //关闭选择人
    handleCloseUser() {
      this.showPop = false
    },

    //日期确认
    onConfirmApply(value) {
      this.formInline.invoiceDate = changeToCurrentDay(value);
      this.showPickerApply = false;
    },

    //优惠后总金额
    handleInputChange() {
      if (this.formInline.price && this.formInline.taxRate) {
        this.formInline.price = (Number(this.formInline.orderPrice) / Number(1 + this.formInline.taxRate / 100)).toFixed(3)
        this.formInline.taxPrice = (Number(this.formInline.price) * Number(this.formInline.taxRate / 100)).toFixed(3)
      }
    },

    //开票类型确认
    onConfirmType(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //确认抬头
    async onConfirmHead(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['id'];
      this.formInline[keyName] = event[eventKey];
      this.formInline['typeStr'] = event['typeStr']
      this.formInline['number'] = event['number']
      this.formInline['bank'] = event['bank']
      this.formInline['accountName'] = event['accountName']
      this.formInline['accountAddress'] = event['accountAddress']
      this.formInline['phone'] = event['phone']
      this[visible] = false;
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    async onSubmit() {
      let data = JSON.stringify(this.formInline)

      const result = this.formInline.id ? await updateCrmInvoice(data) : await addCrmInvoice(data);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

.list-tit-wrap {
  padding: 24px 30px;
  font-size: 26px;
  color: #333333;
}

.add-btn {
  width: 750px;
  height: 110px;
  background: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  line-height: 110px;
  font-size: 30px;
  color: #54afff;
  cursor: pointer;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
